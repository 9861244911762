// eslint-disable-next-line
export default (CURRENT_ENV, country) => ({
  config: 'common',
  defaultJurisdiction: 'mga',
  CURRENT_ENV,
  type: 'pnp',
  indexContainer: 'IndexContainerPnp',
  skinId: CURRENT_ENV === 'dev' ? 31 : 38,
  defaultLocale: 'en-US',
  defaultTitle: 'Sportsbook, Casino, Live Casino and Virtual Sports at Fastbet',
  MERCHANT_ID: CURRENT_ENV === 'dev' ? 100014996 : 100014041,
  sendActivationCodeOnSignUp: false,
  disableActivationStep: true,
  content: {
    space: 'iMRCFP05n3T1',
    host: 'https://delivery2.objectic.io',
    access_token:
      CURRENT_ENV === 'dev'
        ? 'CxmbGFqOviH3GllLTKUygIp0w1rhSC6Dv5Zg5y5U'
        : 'ZQ3aGw5Bt0lkYvxELlSOpYJzpbODk5ezdhKgdnuv',
    preview_host: 'https://preview.objectic.io',
    preview_access_token:
      CURRENT_ENV === 'dev'
        ? 'CxmbGFqOviH3GllLTKUygIp0w1rhSC6Dv5Zg5y5U'
        : 'ZQ3aGw5Bt0lkYvxELlSOpYJzpbODk5ezdhKgdnuv',
    environment:
      CURRENT_ENV === 'dev'
        ? 'stage'
        : 'master',
  },
  games: {
    space: 'jIWXpQOLRVBo',
    host: 'https://delivery2.objectic.io',
    access_token: '768|pmGig0SgkbP6dNn8LNtdxfCGSSqGHsfJSLhXlCBc',
    preview_host: 'https://preview.objectic.io',
    preview_access_token: '768|pmGig0SgkbP6dNn8LNtdxfCGSSqGHsfJSLhXlCBc',
    environment: CURRENT_ENV === 'dev' ? 'master' : 'master',
    gameIdKey: CURRENT_ENV === 'dev' ? 'tg-pz-stage' : 'tg-pz',
  },
  axiosCache: {
    duration: 60, // 1 minute
    whitelist: ['delivery2.objectic.io', '/event-feed/jackpots'],
  },
  jurisdictions: [
    {
      key: 'mga',
      name: 'fastbet.com',
      domain: CURRENT_ENV === 'prod' ? 'www.fastbet.com' : null,
    },
    {
      key: 'sga',
      name: 'fastbet.com (se)',
      domain: CURRENT_ENV === 'prod' ? 'www.fastbet.com' : null,
    },
  ],
  tgplay: {
    host:
      CURRENT_ENV === 'dev'
        ? 'https://play-togethergaming-new-config-stage.azurewebsites.net'
        : 'https://play.fastbet.com',
    skin: 'fastbet_pz',
    standalone: true,
  },
  aleacc: {
    host:
      CURRENT_ENV === 'dev'
        ? 'https://api-stage-fastbet.codebet.dev'
        : 'https://api.fastbet.com',
  },
  devcode: {
    host:
      CURRENT_ENV === 'dev'
        ? 'https://test-bo.paymentiq.io/paymentiq/api'
        : 'https://api.paymentiq.io/paymentiq/api',
    oauth_host:
      CURRENT_ENV === 'dev'
        ? 'https://test-api.paymentiq.io/paymentiq/oauth'
        : 'https://backoffice.paymentiq.io/paymentiq/oauth',
    oauth_provider: 'trustly',
    oauth_clientid:
      CURRENT_ENV === 'dev'
        ? '9b6e3d0cf9eb4a07b90b49ad2b1fa311'
        : '6d8040bded3b49af86fdccf1f93d9f34',
    oauth_redirecturl:
      CURRENT_ENV === 'dev'
        ? 'https://stage-fastbet.codebet.dev/nemid.html'
        : 'https://www.fastbet.com/nemid.html',

    oauth_ui_friendly: 'bethard-1',
  },
  springBuilder: {
    host:
      CURRENT_ENV === 'dev'
        ? 'https://stage-sports.fastbet.com'
        : 'https://sports.fastbet.com',
  },
  authenticgaming: {
    host:
      CURRENT_ENV === 'dev'
        ? 'http://game.authenticstage.live/game/loader.aspx?'
        : 'https://game.itsreal.live//game/loader.aspx?',
    operatorId: 38,
  },
  freshChat: {
    token: "c44b51b7-1e7b-4df7-8009-72478339d0df",
    host: "https://bethard.freshchat.com",
    widgetUuid: "97356f90-7bd1-4c91-b841-d87949bdbff4",
    brand: 'fastbet',
    appName: 'Fastbet',
    appImage:
      CURRENT_ENV === 'dev'
        ? 'https://stage-fastbet.codebet.dev/skin/images/chatavatar.png'
        : 'https://www.fastbet.com/skin/images/chatavatar.png',
    siteId: 'fastbet',
    tags: ['welcomefastbet', 'issuefastbet'],
    config: {
      headerProperty: {
      },
      cssNames: {
        widget: 'fc_frame',
        open: 'fc_open',
      },
    },
  },

  /**
   * Show the bonus selector in the PNP flow
   */
  showBonusSelector: true,

  /**
   * Show the bonus selector only on sign up
   */
  onlyShowBonusOnSignUp: true,

  /**
   * Configure which chat to use.
   * @description
   * Current available chat options are FreshChat (freshchat), LiveAgent (ladesk), LiveChat (livechat).
   */
  chat: {
    provider: 'freshchat',
  },
  /**
   * Visibility configuration for user registration.
   * @description
   * Current available elements for the array: ['email', 'phone']
   */
  userFields: ['email'],
  /**
   * Validation rules used for the email field in the details step.
   * @description
   * Current available rules: "validationPattern", "transformationPattern", "required"
   */
  emailValidationRules: {
    validationPattern:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    transformationPattern: null,
    required: true,
  },
  /**
   * Validation rules used for the phone field in the details step.
   * @description
   * Current available rules: "validationPattern", "transformationPattern", "required"
   */
  phoneValidationRules: {
    validationPattern: /^[0-9]{9,}$/,
    transformationPattern: /^0+/,
    required: true,
  },
  /**
   * How the index page features should be displayed
   * @description
   * Current available options are 'row', 'slider', 'textSlider' and 'iconSlider'
   * Default :
   * desktop: 'row',
   * mobile: 'slider'
   */
  indexFeatures: {
    desktop: 'row',
    mobile: 'slider',
  },

  /**
   * If the sign in/fetch balance button should be displayed in header or base page.
   * @description
   * Default is true which means it should be in header.
   * showFetchBalanceIndexHeader: true
   */
  showFetchBalanceIndexHeader: true,

  globalNavContainer: false,

  products: ['casino', 'payments', 'sportsbook'],

  hideOnScroll: {
    footer: true,
  },

  /**
   * Show sticky footer on desktop
   */
  showStickyFooter: false,

  /**
   * Show sticky footer sign up CTA
   */
  stickyFooterButton: false,

  installApp: true,

  /*
   * Choose if support button in header/dashboard should navigate to page or open support widget
   */
  openSupportWidget: {
    header: false,
    sidebar: true,
  },

  /**
   * Casino options
   * @property layout Game grid layout (slider, grid)
   * @property searchStyle Show casino categories (categories, noCategories)
   */
  casino: {
    layout: 'slider',
    casinoCategoryMenuStyle: 'categories',
    liveCasinoData: true,
    sliderType: 'draggable',
    sliderSettings: {
      isCategory: {
        1200: 8,
        1400: 10,
        2400: 12,
        default: 16,
      },
    },
  },

  disablePreGameView: true,

  /**
   * Options for communication consent checkbox on details step.
   */
  communicationConsent: {
    enabled: true,
    values: [
      'BonusEmails',
      'BonusSMSs',
      'NewsEmails',
      'NewsSMSs',
      'Phone',
      'Casino',
      'Sportsbook',
    ],
  },

  quickDeposit: {
    enabled: false,
    host: 'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.23/tg-quickdeposit.bundle.js',
    successUrl:
      'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.23/success.html',
    failureUrl:
      'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.23/failure.html',
    cancelUrl:
      'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.23/cancel.html',
  },

  showQuickAmounts: true,

  useVisitedCookie: true,
  visitedCookieName: 'tg-page-visited',
  visitedCookieMaxAge: 365 * 24 * 60 * 60 * 1000,
  gameHistoryName: 'tg-game-history',
  btagCookie: 'tg-btag',
  defaultCurrency: 'EUR',

  GTM_ID: 'GTM-KWP7RZH',

  redirects: [
    {
      source: /^\/unsub/,
      target: () => '/fetch-balance?ref_url=dashboard/subscriptions',
    },
    {
      source: '/deposit',
      target: () => '/start-playing',
    },
    {
      source: '/withdraw',
      target: () => '/#withdraw',
    },
    {
      source: /^\/(sign-in|login)$/,
      target: () => '/fetch-balance',
    },
    {
      source: '/sign-up',
      target: () => '/fetch-balance',
    },
    {
      source: '/dashboard',
      target: () => '/#dashboard',
    },
    {
      source: /^\/(dashboard\/activate|activate)$/,
      target: () => '/#dashboard/activate',
    },
    {
      source: '/dashboard/responsible-gaming',
      target: () => '/#dashboard/responsible-gaming',
    },
    {
      source: '/dashboard/subscriptions',
      target: () => '/#dashboard/subscriptions',
    },
    {
      source: '/dashboard/verify',
      target: () => '/#dashboard/verify',
    },
    {
      source: '/dashboard/bonus',
      target: () => '/#dashboard/bonus',
    },
    {
      source: '/dashboard/history',
      target: () => '/#dashboard/history',
    },
    {
      source: '/dashboard/messages',
      target: () => '/#dashboard/messages',
    },
    {
      source: '/dashboard/edit-profile',
      target: () => '/#dashboard/edit-profile',
    },
    {
      source: '/dashboard/change-password',
      target: () => '/#dashboard/change-password',
    },
    {
      source: '/sbtech/sbtech.js',
      target: () => '/sbtech.js',
    },
  ],
  /**
   * @param {Request} [req] Provide the request object if this function is being used on the server
   * @returns {(string|boolean)}
   */
  cookieDomain: (req) => {
    if (!__CLIENT__ && !req) return false;
    return (__CLIENT__ ? window.location.href : req.get('host')).includes(
      'fastbet.com'
    )
      ? '.fastbet.com'
      : false;
  },
});
