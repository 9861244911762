import React from 'react';
import { Select, Button } from 'tg-core-components';
import cn from 'classnames';
import { getContinueLabel } from '../../helpers';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { format as moneyFormat } from 'tg-core-components/lib/components/Money';
import { validateRules, required } from '../../validation';
import { useSelector } from 'react-redux';
import marked from 'marked';

import './style.css';

const AnnualActivity = ({
  errors,
  onChange,
  fields,
  nextStep,
  statuses,
  intl,
  device,
}) => {
  const cmsConfig = useSelector(state => state.content.config.data);
  const sections = useSelector(state => state.content.sections.data);

  const isValid = statuses.ExpectedAnnualLevelOfActivity === 'success';
  const currency = fields.Currency || 'EUR';

  const activityOptions = cmsConfig?.find(c => c.key === 'annual-activity')
    ?.value;

  const formattedValues = activityOptions?.[currency].map(v => ({
    max: v.max ? moneyFormat(currency).format(v.max) : '',
    min: moneyFormat(currency).format(v.min),
  }));

  const annualActivityContent = sections?.find(
    s => s.identifier === 'info.annual-activity'
  )?.content;

  const value = fields.ActivityOptions?.[currency].find(a =>
    a.min.includes(fields.ExpectedAnnualLevelOfActivity)
  );

  const createValueString = value => {
    return `${value.min}${value.max ? ' - ' : ''}${
      value.max ? value.max : '+'
    }`;
  };

  return (
    <form onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      <Select
        name="Activity"
        value={value}
        onChange={(_, value) =>
          onChange({
            ExpectedAnnualLevelOfActivity: value,
          })
        }
        status={statuses.ExpectedAnnualLevelOfActivity}
        statusText={
          errors.ExpectedAnnualLevelOfActivity &&
          translate({ id: errors.ExpectedAnnualLevelOfActivity }, intl)
        }>
        <Select.Option value="" selected disabled>
          {translate(
            {
              id: 'label.expected-annual-activity',
              defaultMessage: 'Expected Annual Activity',
            },
            intl
          )}
        </Select.Option>
        {formattedValues.map((c, i) => (
          <Select.Option
            key={i}
            value={createValueString(c)}
            hasFocus={formattedValues[1] === c}>
            {c.min}
            {i === formattedValues.length - 1 ? ' + ' : ' - '}
            {c.max ? c.max : ''}
          </Select.Option>
        ))}
      </Select>
      {annualActivityContent && (
        <div className="annual-activity-notice">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 973.1 973.1"
            width="30"
            height="30">
            <path
              fill="currentColor"
              d="M502.29 788.199h-47c-33.1 0-60 26.9-60 60v64.9c0 33.1 26.9 60 60 60h47c33.101 0 60-26.9 60-60v-64.9c0-33.199-26.899-60-60-60zM170.89 285.8l86.7 10.8c27.5 3.4 53.6-12.4 63.5-38.3 12.5-32.7 29.9-58.5 52.2-77.3 31.601-26.6 70.9-40 117.9-40 48.7 0 87.5 12.8 116.3 38.3 28.8 25.6 43.1 56.2 43.1 92.1 0 25.8-8.1 49.4-24.3 70.8-10.5 13.6-42.8 42.2-96.7 85.9-54 43.7-89.899 83.099-107.899 118.099-18.4 35.801-24.8 75.5-26.4 115.301-1.399 34.1 25.8 62.5 60 62.5h49c31.2 0 57-23.9 59.8-54.9 2-22.299 5.7-39.199 11.301-50.699 9.399-19.701 33.699-45.701 72.699-78.1C723.59 477.8 772.79 428.4 795.891 392c23-36.3 34.6-74.8 34.6-115.5 0-73.5-31.3-138-94-193.4-62.6-55.4-147-83.1-253-83.1-100.8 0-182.1 27.3-244.1 82-52.8 46.6-84.9 101.8-96.2 165.5-3.501 18.6 9.199 36 27.699 38.3z"></path>
          </svg>
          <div
            dangerouslySetInnerHTML={{
              __html: marked(annualActivityContent),
            }}
            className="annual-activity-content"
          />
        </div>
      )}
      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ ExpectedAnnualLevelOfActivity }) => {
  const rules = {
    ExpectedAnnualLevelOfActivity: [
      [required, 'error.empty.expected-annual-activity'],
    ],
  };

  const errors = await validateRules({ ExpectedAnnualLevelOfActivity }, rules);

  return Promise.resolve({ errors });
};

export default {
  name: 'activity',
  Component: AnnualActivity,
  icon: 'question-circle',
  validate,
  fields: ['ExpectedAnnualLevelOfActivity'],
};
