import {
  email,
  phone,
  required,
  validateRules,
} from '@components/PayNPlay/validation';
import { useConfig } from '@utils/config';
import marked from 'marked';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  countryEmojiFlags,
  Input,
  Select,
  Translate,
} from 'tg-core-components';
import translate from 'tg-core-components/lib/lib/utils/translate';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import selectCountries from '@selectors/selectCountries';
import { format as moneyFormat } from 'tg-core-components/lib/components/Money';

const Contact = ({
  /* Props from parent */
  fields,
  onChange,
  nextStep,
  FirstNameRequired,
  sessionId,
  errors,
  intl,
  statuses,

  /* Props from selector */
  sections,
  countries,
  ipCountry,
}) => {
  const jurisdiction = useSelector(state => state.app.jurisdiction);
  const cmsConfig = useSelector(state => state.content.config.data);
  const currency = fields.Currency || 'EUR';
  const config = useConfig();
  const [isLoading, setIsLoading] = useState(false);

  const { userFields } = config;

  const dispatch = useDispatch();

  const stepContent = sections?.find(
    s => s.identifier === 'header.paynplay.details'
  )?.content;

  const annualActivityContent = sections?.find(
    s => s.identifier === 'info.annual-activity'
  )?.content;

  const activityOptions = cmsConfig?.find(c => c.key === 'annual-activity')
    ?.value;

  const formattedValues = activityOptions?.[currency].map(v => ({
    max: v.max ? moneyFormat(currency).format(v.max) : '',
    min: moneyFormat(currency).format(v.min),
  }));

  const value = fields.ActivityOptions?.[currency].find(
    a => a.min == fields.ExpectedAnnualLevelOfActivity
  );

  const createValueString = value => {
    return `${value.min}${value.max ? ' - ' : ''}${
      value.max ? value.max : '+'
    }`;
  };

  const onSubmit = async e => {
    e.preventDefault();

    setIsLoading(true);

    const setDetailsResponsePromise = dispatch(
      playerActions.setDetailsWithoutPassword(sessionId, {
        Email: fields.Email,
        Mobile: userFields.includes('phone') ? fields.MobilePhoneNumber : null,
        FirstName: fields.FirstName,
      })
    );

    const setExpectedAnnualActivityPromise = dispatch(
      playerActions.setExpectedAnnualActivity(
        sessionId,
        fields.ExpectedAnnualLevelOfActivity
      )
    );

    const [setDetailsResponse, setExpectedAnnualActivity] = await Promise.all([
      setDetailsResponsePromise,
      setExpectedAnnualActivityPromise,
    ]);

    setIsLoading(false);

    if (setDetailsResponse.status && setExpectedAnnualActivity.status) {
      nextStep();
    }
  };

  const isValid =
    !isLoading &&
    (userFields.includes('email') ? statuses.Email === 'success' : true) &&
    (userFields.includes('phone')
      ? statuses.MobilePhoneNumber === 'success'
      : true) &&
    (jurisdiction === 'mga'
      ? statuses.ExpectedAnnualLevelOfActivity === 'success'
      : true) &&
    (FirstNameRequired === 'true' ? statuses.FirstName === 'success' : true);

  return (
    <div className="Contact">
      {stepContent && (
        <div
          dangerouslySetInnerHTML={{ __html: marked(stepContent) }}
          className="Contact__content"
        />
      )}

      <form onSubmit={onSubmit}>
        {userFields.includes('email') && (
          <Input
            autoFocus
            type="email"
            name="Email"
            value={fields.Email}
            label={translate(
              {
                id: 'label.paynplay.email_placeholder',
                defaultMessage: 'E-mail',
              },
              intl
            )}
            onChange={(_, Email) => onChange({ Email })}
            status={statuses.Email}
            statusText={
              errors.Email &&
              translate(
                {
                  id: errors.Email,
                },
                intl
              )
            }
          />
        )}

        {userFields.includes('phone') && (
          <Input
            autoFocus={fields.Email}
            name="MobilePhoneNumber"
            type="tel"
            value={fields.MobilePhoneNumber || ''}
            onChange={(_, MobilePhoneNumber) => {
              onChange({ MobilePhoneNumber });
            }}
            callingCodes={countries
              ?.sort((a, b) => Number(a.callingCode) - Number(b.callingCode))
              ?.sort((a, b) =>
                a.value === ipCountry ? -1 : b.value === ipCountry ? 1 : 0
              )
              ?.map(i => ({
                value: '00' + i.callingCode,
                label: `+${i.callingCode} (${i.value}) ${countryEmojiFlags[
                  i.value
                ] || ''}`,
              }))}
            maxLength="17"
            status={
              fields.MobilePhoneNumber?.length > 6 && statuses.MobilePhoneNumber
            }
            statusText={
              fields.MobilePhoneNumber?.length > 6 &&
              errors.MobilePhoneNumber &&
              translate({ id: errors.MobilePhoneNumber }, intl)
            }
          />
        )}

        {FirstNameRequired === 'true' && (
          <Input
            autoFocus={fields.Email && fields.MobilePhoneNumber}
            status={statuses.FirstName}
            statusText={
              errors.FirstName && translate({ id: errors.FirstName }, intl)
            }
            label={translate(
              {
                id: 'label.paynplay.first_name_placeholder',
                defaultMessage: 'First name',
              },
              intl
            )}
            value={fields.FirstName}
            onChange={e => onChange({ FirstName: e.target.value })}
          />
        )}

        {jurisdiction === 'mga' && (
          <div className="AnnualActivity">
            <Select
              name="Activity"
              value={value}
              onChange={(_, value) =>
                onChange({
                  ExpectedAnnualLevelOfActivity: value,
                })
              }
              status={statuses.ExpectedAnnualLevelOfActivity}
              statusText={
                errors.ExpectedAnnualLevelOfActivity &&
                translate({ id: errors.ExpectedAnnualLevelOfActivity }, intl)
              }>
              <Select.Option value="" selected disabled>
                {translate(
                  {
                    id: 'label.expected-annual-activity',
                    defaultMessage: 'Expected Annual Activity',
                  },
                  intl
                )}
              </Select.Option>
              {formattedValues.map((c, i) => (
                <Select.Option
                  key={i}
                  value={createValueString(c)}
                  hasFocus={formattedValues[1] === c}>
                  {c.min}
                  {i === formattedValues.length - 1 ? ' + ' : ' - '}
                  {c.max ? c.max : ''}
                </Select.Option>
              ))}
            </Select>
            {annualActivityContent && (
              <div className="annual-activity-notice">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 973.1 973.1"
                  width="30"
                  height="30">
                  <path
                    fill="currentColor"
                    d="M502.29 788.199h-47c-33.1 0-60 26.9-60 60v64.9c0 33.1 26.9 60 60 60h47c33.101 0 60-26.9 60-60v-64.9c0-33.199-26.899-60-60-60zM170.89 285.8l86.7 10.8c27.5 3.4 53.6-12.4 63.5-38.3 12.5-32.7 29.9-58.5 52.2-77.3 31.601-26.6 70.9-40 117.9-40 48.7 0 87.5 12.8 116.3 38.3 28.8 25.6 43.1 56.2 43.1 92.1 0 25.8-8.1 49.4-24.3 70.8-10.5 13.6-42.8 42.2-96.7 85.9-54 43.7-89.899 83.099-107.899 118.099-18.4 35.801-24.8 75.5-26.4 115.301-1.399 34.1 25.8 62.5 60 62.5h49c31.2 0 57-23.9 59.8-54.9 2-22.299 5.7-39.199 11.301-50.699 9.399-19.701 33.699-45.701 72.699-78.1C723.59 477.8 772.79 428.4 795.891 392c23-36.3 34.6-74.8 34.6-115.5 0-73.5-31.3-138-94-193.4-62.6-55.4-147-83.1-253-83.1-100.8 0-182.1 27.3-244.1 82-52.8 46.6-84.9 101.8-96.2 165.5-3.501 18.6 9.199 36 27.699 38.3z"></path>
                </svg>
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(annualActivityContent),
                  }}
                  className="annual-activity-content"
                />
              </div>
            )}
          </div>
        )}

        <Button
          className="button primary"
          type="submit"
          disabled={!isValid}
          isLoading={isLoading}>
          <Translate id="action.save" defaultMessage="Save" />
        </Button>
      </form>
    </div>
  );
};

const validate = async ({
  Email,
  MobilePhoneNumber,
  FirstName,
  ExpectedAnnualLevelOfActivity,
}) => {
  const rules = {
    Email: [
      [required, 'error.paynplay.details_email'],
      [email, 'error.paynplay.details_email'],
    ],
    MobilePhoneNumber: [
      [required, 'error.paynplay.details_phone'],
      [phone, 'error.paynplay.details_phone'],
    ],
    FirstName: [[required, 'error.paynplay.details_first_name']],
    ExpectedAnnualLevelOfActivity: [
      [required, 'error.empty.expected-annual-activity'],
    ],
  };
  const errors = await validateRules(
    { Email, MobilePhoneNumber, FirstName, ExpectedAnnualLevelOfActivity },
    rules
  );

  return Promise.resolve({ errors });
};

const selector = state => ({
  sections: state.content.sections.data,
  countries: selectCountries(state),
  ipCountry: state.app.ipCountry,
});

export default {
  name: 'contact',
  Component: Contact,
  validate,
  selector,
  fields: [
    'Email',
    'MobilePhoneNumber',
    'FirstName',
    'ExpectedAnnualLevelOfActivity',
  ],
};
