// eslint-disable-next-line
export default (CURRENT_ENV, country) => ({
  config: 'sga',
  countries: ['se'],
  sendActivationCodeOnSignUp: false,
  tgplay: {
    skin: 'fastbet_pz_sga',
  },
  springBuilder: {
    host:
      CURRENT_ENV === 'prod'
        ? 'https://sports-se.fastbet.com'
        : 'https://stage-sports.fastbet.com',
  },
  devcode: {
    oauth_provider: 'trustly',
  },
});
