import { makeApiCallAction } from 'tg-core-redux/lib/lib/action';
import deviceDetect from '@utils/deviceDetect';
import { getConfig } from '@config';
import * as contentUtils from '@utils/content';
import { bindReferences } from '@utils/segmentation';

global.__LOAD_STATE__ = global.__LOAD_STATE__ || {};

const checkGameRules = (game, rules, isAuthenticated) => {
  const { provider, subprovider = '', tags = [], jurisdiction } = game;
  if (rules.providers?.includes(provider.toLowerCase())) return false;
  if (rules.subproviders?.includes(subprovider.toLowerCase())) return false;
  if (rules.jurisdictions?.includes(jurisdiction.toLowerCase())) return false;
  if (rules.tags?.some(t => tags.indexOf(t) >= 0)) return false;
  if (!isAuthenticated && tags.indexOf('authenticated') >= 0) return false;

  return true;
};

export const segmentGame = (
  game,
  country,
  ipcountry = '',
  isAuthenticated,
  zip,
  rules
) => {
  const gameRules = rules.filter(
    r =>
      (r.value || '').toLowerCase() === (country || '').toLowerCase() ||
      (r.value || '').toLowerCase() === (ipcountry || '').toLowerCase()
  );

  return gameRules
    .map(r => {
      if (!r.disabled_games) return true;

      let includeGame = true;

      const zipRules = r.disabled_games?.zip_codes?.filter(zipObj =>
        zipObj?.zip?.some(regexp => new RegExp(regexp, 'i').test(zip))
      );

      if (zipRules?.length) {
        includeGame = zipRules.every(zipRule =>
          checkGameRules(game, zipRule, isAuthenticated)
        );
      }

      if (includeGame) {
        includeGame = checkGameRules(game, r.disabled_games, isAuthenticated);
      }

      return includeGame;
    })
    .reduce((p, c) => p && c, true);
};

const filterBySegmentation = state => {
  const userCountry = state.player?.user?.Country;
  const ipCountry = state.app?.ipCountry;
  const isAuthenticated = state.player?.isAuthenticated;
  const userZip = state?.player?.user?.Zip;
  const countriesConfig = state.content.config?.data?.find(
    c => c.key === 'countries'
  ).value;

  return game =>
    segmentGame(
      game,
      userCountry,
      ipCountry,
      isAuthenticated,
      userZip,
      countriesConfig
    );
};

const handleResponse = (items = [], gameIdKey, device, state) =>
  items
    ?.map(game => ({
      ...game,
      backendId:
        game?.gameIds?.[gameIdKey]?.[device !== 'Desktop' ? 'mid' : 'id'],
      tableId: game?.gameIds?.[gameIdKey]?.tid,
      backendTableId:
        game?.gameIds?.[gameIdKey]?.[device !== 'Desktop' ? 'mbtid' : 'btid'],
    }))
    ?.filter(game => game?.backendId)
    ?.filter(filterBySegmentation(state));

export const fetchGames = (locale, query, options) => (dispatch, getState) => {
  const { jurisdiction } = getState().app;
  const { games: config, defaultLocale } = getConfig(jurisdiction);
  const device = deviceDetect(window.navigator.userAgent);

  query = {
    'fields.platforms[match]': device.toLocaleLowerCase(),
    'fields.jurisdictions[match]': jurisdiction.toLocaleLowerCase(),
    limit: 1000,
    ...query,
  };

  return dispatch(
    makeApiCallAction(
      'FETCH_GAMES',
      Promise.all([
        contentUtils.fetchContent(
          'game',
          locale,
          query,
          config,
          options && options.cookies
        ),
        contentUtils.fetchContent(
          'game',
          locale,
          { ...query, skip: 1000 },
          config,
          options && options.cookies
        ),
      ]).then(res => {
        return handleResponse(
          res.flatMap(r => bindReferences(r.data)?.items.map(i => i.fields)),
          config.gameIdKey,
          device,
          getState(),
          defaultLocale
        );
      })
    )
  );
};
